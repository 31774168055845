import Image from 'next/image';

export function GlobalErrorMessage() {
  return (
    <div className="animate-fade-in">
      <Image
        className="mx-auto w-full max-w-5xl"
        alt="You are currently not able to access our website due to technical problems. We expect the website to be accessible again in a few minutes. We apologise for any inconvenience."
        src="/500.png"
        width={1024}
        height={666}
      />
      <div className="mx-auto max-w-3xl text-center text-lg text-gray-500">
        <br />
        <br />
        {/* United Kingdom */}
        <strong>UNITED KINGDOM</strong>
        <br />
        You are currently not able to access our website due to technical problems. We expect the website to be
        accessible again in a few minutes. We apologise for any inconvenience.
        <br />
        Best regards
        <br />
        JYSK
        <br />
        <br />
        {/* Danmark */}
        <strong>DANMARK</strong>
        <br />
        Der er i &oslash;jeblikket ingen adgang til vores hjemmeside pga. tekniske problemer. Vi forventer at der er
        adgang igen om et par minutter. Vi beklager ulejligheden. <br />
        Med venlig hilsen <br />
        JYSK
        <br />
        <br />
        {/* Norge */}
        <strong>NORGE</strong>
        <br />
        På grunn av tekniske problemer er hjemmesiden vår dessverre ikke tilgjengelig i øyeblikket. Vi forventer at
        hjemmesiden vil være tilgjengelig i løpet av få minutter. Vi beklager feilen. <br />
        Vennlig hilsen <br />
        JYSK
        <br />
        <br />
        {/* Sverige */}
        <strong>SVERIGE</strong>
        <br />
        För närvarande kan du inte komma åt vår hemsida på grund av tekniska problem. Vi räknar med att webbplatsen ska
        vara tillgänglig igen om några minuter. Vi ber om ursäkt för eventuella besvär.
        <br />
        Med vänliga hälsningar
        <br />
        JYSK
        <br />
        <br />
        {/* Finland */}
        <strong>SUOMI</strong>
        <br />
        Teknisest&auml; viasta johtuen kotisivua ei voi avata. Sivun pit&auml;isi aueta muutamassa minuutissa.
        Pahoittelemme virhett&auml;.
        <br />
        Ystävällisin terveisin,
        <br />
        JYSK
        <br />
        <br />
        {/* Holland */}
        <strong>NEDERLAND</strong>
        <br />
        Vanwege technische problemen kunt u onze website momenteel niet bekijken. We verwachten dat alles binnen een
        paar minuten weer werkt. Onze excuses voor het ongemak.
        <br />
        Vriendelijke groeten
        <br />
        JYSK
        <br />
        <br />
        {/* Polen */}
        <strong>POLSKA</strong>
        <br />
        Z powod&oacute;w technicznych strona jest tymczasowo niedost&#x0119;pna. Problem powinien zosta&#x0107;
        rozwi&#x0105;zany w ci&#x0105;gu kilku minut. Przepraszamy za niedogodno&#x015b;ci.
        <br />Z pozdrowieniami
        <br />
        JYSK
        <br />
        <br />
        {/* Ukrainian */}
        <strong>УКРАЇНА</strong>
        <br />
        На даний момент сайт не працює з технічних причин. Ми робимо усе можливе, аби вже через кілька хвилин Ви могли
        відвідати наш сайт.
        <br />
        Перепрошуємо за незручності
        <br />
        JYSK
        <br />
        <br />
        {/* Tjekkiet */}
        <strong>ČESKÁ REPUBLIKA</strong>
        <br />
        Tato str&aacute;nka bohu&#x017e;el pr&aacute;v&#x011b; nen&iacute; z technick&yacute;ch d&#x016f;vod&#x016f;
        dostupn&aacute;. P&#x0159;edpokl&aacute;d&aacute;me, &#x017e;e k odstran&#x011b;n&iacute; z&aacute;vady dojde
        b&#x011b;hem n&#x011b;kolika minut. Omlouv&aacute;me se za zp&#x016f;soben&eacute; nep&#x0159;&iacute;jemnosti.
        <br />S p&#345;átelským pozdravem
        <br />
        JYSK
        <br />
        <br />
        {/* Ungarn */}
        <strong>MAGYARORSZÁG</strong>
        <br />
        Technikai probl&eacute;m&aacute;k miatt honlapunk jelenleg nem el&eacute;rhet&#x0151;. V&aacute;rhat&oacute;an
        p&aacute;r percen bel&uuml;l azonban ism&eacute;t m&#x0171;k&ouml;d&#x0151;k&eacute;pes lesz. A
        kellemetlens&eacute;g&eacute;rt eln&eacute;z&eacute;s&eacute;t k&eacute;rj&uuml;k.
        <br />
        Üdvözlettel <br />
        JYSK
        <br />
        <br />
        {/* Slovakiet */}
        <strong>SLOVENSKO</strong>
        <br />
        T&aacute;to str&aacute;nka nie je z technick&yacute;ch d&ocirc;vodov dostupn&aacute;. Predpoklad&aacute;me,
        &#x017e;e bude pr&iacute;stupn&aacute; za niekolko min&uacute;t. Ospravedlnujeme sa za sp&ocirc;soben&eacute;
        nepr&iacute;jemnosti.
        <br />
        S priatelským pozdravom
        <br />
        JYSK
        <br />
        <br />
        {/* Kroatien */}
        <strong>HRVATSKA</strong>
        <br />
        Trenutno niste u mogu&#x0107;nosti pristupiti na&#x0161;oj stranici zbog tehni&#x010d;kih problema.
        O&#x010d;ekujemo da &#x0107;e stranica ponovno biti dostupna za nekoliko minuta. Ispri&#x010d;avamo se zbog
        neugodnosti.
        <br />
        Srdacan pozdrav,
        <br />
        JYSK
        <br />
        <br />
        {/* Slovenien */}
        <strong>SLOVENIJA</strong>
        <br />
        Zaradi tehnicnih te&#x017e;av trenutno ne morete dostopati do na&#x0161;e spletne strani. Pricakujemo, da bo
        stran ponovno na voljo v nekaj minutah. Za morebitne te&#x017e;ave se opravicujemo.
        <br />
        Lep pozdrav
        <br />
        JYSK
        <br />
        <br />
        {/* Bosnien */}
        <strong>BOSNA I HERCEGOVINA</strong>
        <br />
        Trenutno niste u mogu&#x0107;nosti da pristupite na&#x0161;oj stranici zbog tehni&#x010d;kih problema.
        O&#x010d;ekujemo da &#x0107;e stranica ponovo biti dostupna za nekoliko minuta. Izvinjavamo se zbog
        neugodnosti..
        <br />
        Srda&#269;an pozdrav
        <br />
        JYSK
        <br />
        <br />
        {/* Serbien */}
        <strong>SRBIJA</strong>
        <br />
        Trenutno niste u mogucnosti da pristupite na&#x0161;em sajtu zbog tehnickih problema. Ocekujemo da ce sajt biti
        ponovo dostupan za nekoliko minuta. Izvinjavamo se zbog neugodnosti
        <br />
        Lep pozdrav
        <br />
        JYSK
        <br />
        <br />
        {/* България */}
        <strong>България</strong>
        <br />
        В момента нямате достъп до нашия уебсайт поради технически проблеми. Очакваме уебсайтът да бъде достъпен отново
        след няколко минути. Извиняваме се за причинените неудобства.
        <br />
        С най-добри пожелания,
        <br />
        JYSK
        <br />
        <br />
        {/* AT Austria */}
        <strong>Österreich</strong>
        <br />
        Aufgrund technischer Probleme kannst Du aktuell nicht auf unsere Webseite zugreifen. Wir erwarten, dass die
        Seite in wenigen Minuten wieder erreichbar ist. Wir entschuldigen uns für die Unannehmlichkeiten.
        <br />
        Viele Grüsse,
        <br />
        JYSK
        <br />
        <br />
        {/* DE Deutschland */}
        <strong>Deutschland</strong>
        <br />
        Aufgrund technischer Probleme kannst Du aktuell nicht auf unsere Webseite zugreifen. Wir erwarten, dass die
        Seite in wenigen Minuten wieder erreichbar ist. Wir entschuldigen uns für die Unannehmlichkeiten.
        <br />
        Viele Grüße,
        <br />
        JYSK
        <br />
        <br />
        {/* IT Italia */}
        <strong>Italia</strong>
        <br />
        A causa di problemi tecnici, al momento non è possibile accedere al nostro sito. Il sito sarà di nuovo
        accessibile tra qualche minuto. Ci scusiamo per gli eventuali disagi.
        <br />
        Cordiali saluti,
        <br />
        JYSK
        <br />
        <br />
        {/* FR France */}
        <strong>France</strong>
        <br />
        En raison de problèmes techniques, il vous est actuellement impossible d&apos;accéder à notre site web. Nous
        prévoyons que le site sera à nouveau accessible dans quelques minutes. Nous vous prions de nous excuser pour
        tout désagrément.
        <br />
        Meilleures salutations,
        <br />
        JYSK
        <br />
        <br />
        {/* SWISS-DE Schweiz */}
        <strong>Schweiz</strong>
        <br />
        Aufgrund technischer Probleme kannst du aktuell nicht auf unsere Webseite zugreifen. Wir erwarten, dass die
        Seite in wenigen Minuten wieder erreichbar ist. Wir entschuldigen uns für die Unannehmlichkeiten.
        <br />
        Viele Grüsse,
        <br />
        JYSK
        <br />
        <br />
        {/* SWISS-FR Suisse */}
        <strong>Suisse</strong>
        <br />
        En raison de problèmes techniques, il vous est actuellement impossible d&apos;accéder à notre site web. Nous
        prévoyons que le site sera à nouveau accessible dans quelques minutes. Nous vous prions de nous excuser pour
        tout désagrément.
        <br />
        Meilleures salutations,
        <br />
        JYSK
        <br />
        <br />
        {/* SWISS-IT Svizzera */}
        <strong>Svizzera</strong>
        <br />
        Al momento non è possibile accedere al nostro sito web a causa di problemi tecnici. Prevediamo che il sito web
        sarà di nuovo accessibile in pochi minuti. Ci scusiamo per qualsiasi inconveniente.
        <br />
        Cordiali saluti,
        <br />
        JYSK
        <br />
        <br />
        {/* PT Portugal */}
        <strong>Portugal</strong>
        <br />
        Por motivo de problemas técnicos, de momento não é possível aceder ao nosso site. Esperamos que o site esteja
        novamente acessível dentro de alguns minutos. Pedimos desculpa por qualquer inconveniente.
        <br />
        Melhores cumprimentos,
        <br />
        JYSK
        <br />
        <br />
        {/* ES España */}
        <strong>España</strong>
        <br />
        Debido a un error en nuestra pagina web no podemos atenderte ahora. En unos instantes la pagina web volverá a
        estar disponible. Te pedimos disculpas por las molestias.
        <br />
        Saludos cordials,
        <br />
        JYSK
        <br />
        <br />
        {/* RO Romania */}
        <strong>Romania</strong>
        <br />
        Din cauza unor probleme tehnice, site-ul nostru nu este funcțional momentan. Ne cerem scuze pentru această
        situație și vă asigurăm că facem tot posibilul pentru a remedia cât mai repede această problemă.
        <br />
        Vă mulțumim pentru înțelegere,
        <br />
        JYSK
        <br />
        <br />
        {/* GR */}
        <strong>Ελλάδα</strong>
        <br />
        Επί της παρούσης δεν είναι δυνατή η πρόσβαση στην ιστοσελίδα μας λόγω τεχνικών προβλημάτων. Αναμένεται να είναι
        ξανά ενεργή εντός λίγων λεπτών. Ζητούμε συγγνώμη για την όποια αναστάτωση.
        <br />
        Με εκτίμηση,
        <br />
        JYSK
        <br />
        <br />
        {/* TR Türkiye */}
        <strong>Türkiye</strong>
        <br />
        Teknik sorunlar nedeniyle şu anda web sitemize erişemiyorsunuz. Web sitesinin birkaç dakika içinde tekrar
        erişilebilir olmasını bekliyoruz. Bu sorundan ötürü özür dileriz.
        <br />
        Saygılarımızla,
        <br />
        JYSK
        <br />
        <br />
      </div>
    </div>
  );
}
